import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import FadeIn, { FADEIN } from 'framers/FadeIn';

import { Container, Layout } from 'layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Accordion, { AccordionVariant } from 'components/Accordion';
import Banner from 'components/Banner';
import Button from 'components/Button';
import { SliderVariant } from 'components/Slider';
import Anchors from 'containers/Anchors';
import AvailableIn from 'containers/AvailableIn';
import Carousel from 'containers/Carousel';
import VideoSection from 'containers/VideoSection';

import sortArr from 'utils/sortArr';
import { useScreenService } from 'hooks/useScreenService';
import { DEFAULT_SCROLL_PARAMS } from 'hooks/useScrollTo/constans';

import './ProductDetailPage.scss';

const { DOWN } = FADEIN;

const ProductDetailsPage: FC<ProductDetailPageTypes.ProductDetailProps> = ({
  data: {
    productDetail: {
      urls,
      seo,
      banner,
      productDescription,
      features,
      button,
      informationalSection,
      whereToBuy,
      whereToBuyText,
      packshot,
      articleCarousel,
      productCarousel,
      videoSection,
      anchors,
      card,
    },
    allOurBrandsDetail,
    allArticleDetail: { articlesList },
    allProductDetail: { productsList },
  },
}) => {
  const { isMobile } = useScreenService();

  const { title, keywords, description } = seo;

  const { ARTICLES, PRODUCTS } = SliderVariant;

  const whereToBuyItems = whereToBuy?.map((buttonEl) => (
    <Button key={buttonEl.ariaLabel} {...buttonEl} className="product-detail-page__shop-button" />
  ));

  const brandLogo = allOurBrandsDetail?.brandsLogo[0]?.brandLogo;

  return (
    <Layout brand={brandLogo}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...banner} banerColor={banner.backgroundColor ?? card.color} />
      <div className="product-detail-page__max-width-wrapper">
        <Anchors
          anchors={anchors}
          className="product-detail-page__anchors hide--on-mobile"
          scrollParams={{ ...DEFAULT_SCROLL_PARAMS, offset: -170 }}
        />
        <Container gutter className="product-detail-page__header">
          <Image
            className="product-detail-page__image"
            {...packshot.imageStructure}
            id="productDetail"
          />
          <Typography
            className="product-detail-page__description"
            as="div"
            dangerouslySetInnerHTML={productDescription}
            animationVariant={DOWN}
          />
        </Container>
      </div>
      {features && features.length ? (
        <Container gutter>
          <ul className="product-detail-page__features">
            {features?.map(({ image, text }) => (
              <li key={text} className="product-detail-page__feature-item">
                {image ? <Image {...image.imageStructure} /> : null}
                <Typography as="div" dangerouslySetInnerHTML={text} animationVariant={DOWN} />
              </li>
            ))}
          </ul>
        </Container>
      ) : null}
      {button?.buttons?.length ? (
        <Container gutter>
          <AvailableIn {...{ button }} />
        </Container>
      ) : null}
      {isMobile && (informationalSection.length || whereToBuy.length) ? (
        <div>
          {informationalSection?.map((information) => (
            <FadeIn key={information.title}>
              <Accordion
                variant={AccordionVariant.BOLD}
                hiddenContent={information.description}
                visibleContent={information.title}
              />
            </FadeIn>
          ))}
          {whereToBuyItems?.length && whereToBuyText ? (
            <FadeIn>
              <Accordion
                variant={AccordionVariant.BOLD}
                hiddenContent={whereToBuyItems}
                visibleContent={whereToBuyText}
              />
            </FadeIn>
          ) : null}
        </div>
      ) : null}
      {!isMobile && informationalSection?.length ? (
        <div className="product-detail-page__max-width-wrapper">
          <Container gutter>
            {whereToBuyText || whereToBuyItems.length ? (
              <div className="product-detail-page__information-bottom">
                <Typography
                  className="product-detail-page__section-heading"
                  size={30}
                  color="sherwood-green"
                  weight="semi-bold"
                  as="h3"
                  animationVariant={DOWN}
                >
                  {whereToBuyText}
                </Typography>
                <motion.div layout className="animate--child-fade">
                  <AnimatePresence>
                    <FadeIn layout>
                      <div className="product-detail-page__shops-items">{whereToBuyItems}</div>
                    </FadeIn>
                  </AnimatePresence>
                </motion.div>
              </div>
            ) : null}
            <div className="product-detail-page__information-top">
              {informationalSection?.map((information) => (
                <div
                  id={information.id}
                  key={information.title}
                  className={classNames(
                    'product-detail-page__information-top-wrapper',
                    {
                      'product-detail-page__information-top-wrapper--ing':
                        information.id === 'ingredients',
                    },
                    {
                      'product-detail-page__information-top-wrapper--how':
                        information.id === 'howToUse',
                    },
                    {
                      'product-detail-page__information-top-wrapper--warn':
                        information.id === 'warnings',
                    }
                  )}
                >
                  <Typography
                    className="product-detail-page__section-heading"
                    size={30}
                    color="sherwood-green"
                    weight="semi-bold"
                    as="h3"
                    dangerouslySetInnerHTML={information.title}
                    animationVariant={DOWN}
                    padding={{ top: 'xl' }}
                  />
                  <Typography
                    className="product-detail-page__section-description"
                    as="div"
                    dangerouslySetInnerHTML={information.description}
                    animationVariant={DOWN}
                  />
                </div>
              ))}
            </div>
          </Container>
        </div>
      ) : null}
      {videoSection ? <VideoSection {...{ videoSection }} /> : null}
      {productsList.length && productCarousel ? (
        <Carousel
          carouselItems={productsList}
          carouselVariant={PRODUCTS}
          carousel={productCarousel}
          sliderId="productsId"
        />
      ) : null}
      {articlesList.length && articleCarousel ? (
        <Carousel
          carouselItems={sortArr(articlesList, 'articleCardOrder')}
          carouselVariant={ARTICLES}
          carousel={articleCarousel}
          sliderId="articlesId"
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query (
    $lang: String
    $path: [String]
    $productsUrls: [String]
    $articlesUrls: [String]
    $brand: String
  ) {
    productDetail(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      banner {
        ...BannerFragment
      }
      packshot {
        ...TransparentImageStructureFragment
      }
      productDescription
      features {
        text
        image {
          ...TransparentImageStructureFragment
        }
      }
      informationalSection {
        ...ContentElementFragment
      }
      whereToBuyText
      whereToBuy {
        ...ButtonFragment
      }
      articleCarousel {
        ...CarouselFragment
      }
      productCarousel {
        ...CarouselFragment
      }
      card {
        color
      }
      videoSection {
        ...VideoSectionFragment
      }
      button {
        ariaLabel
        label
        url
        ui {
          ...ButtonUIFragment
        }
        image {
          ...TransparentImageStructureFragment
        }
        buttons {
          ...ButtonFragment
        }
        windowText
        closeButton {
          ...ButtonFragment
        }
      }
      anchors {
        ...AnchorsFragment
      }
    }
    allOurBrandsDetail(filter: { url: { eq: $brand } }) {
      brandsLogo: nodes {
        brandLogo {
          ...TransparentImageStructureFragment
        }
      }
    }
    allArticleDetail(filter: { url: { in: $articlesUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
        articleCardOrder
      }
    }
    allProductDetail(filter: { url: { in: $productsUrls } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default ProductDetailsPage;
