import React, { FC, useState } from 'react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Typography from 'common/Typography';
import Button from 'components/Button';

import { AvailableInPropsType } from './model';

import './AvailableIn.scss';

const AvailableIn: FC<AvailableInPropsType> = ({ button }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { windowText, buttons, closeButton, ...openButton } = button;

  const contentClassNames = classNames('available-in__content', {
    'available-in__content--visible': isOpen,
  });

  const iconClassNames = classNames('available-in__btn-arrow', {
    'available-in__btn-arrow--active': isOpen,
  });

  return (
    <div className="available-in">
      <Button
        {...openButton}
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        disabled={!buttons.length}
      >
        {openButton.label}
        <FontAwesomeIcon className={iconClassNames} icon={faAngleDown} size="1x" />
      </Button>
      <div className={contentClassNames}>
        <Typography size={{ base: 18, md: 26 }} color="sherwood-green" weight="semi-bold" as="h2">
          {windowText}
        </Typography>
        <div className="available-in__buttons">
          {buttons.map((availableButton) => (
            <Button key={availableButton.label || availableButton.ariaLabel} {...availableButton} />
          ))}
        </div>
        <Button
          className="available-in__close-btn"
          {...closeButton}
          onClick={() => setIsOpen(false)}
        >
          {closeButton.label}
        </Button>
      </div>
    </div>
  );
};

export default AvailableIn;
